<template>
  <div> 
  <CCard class='h-100' v-if="!geofenceNotFound">
    <CCardHeader class="m-0 p-1 d-flex justify-content-between" color="light">
      <span> <b> {{title}} </b> </span>
      <div>
        <CBadge> {{ uid }} </CBadge> 
        <CButton
          color="info"
          @click="openManualEdit(geofence.getUId())"
        >
        Edit
        </CButton>
      </div>
    </CCardHeader>
    <CCardBody class="overflow-auto">
      <CRow>
        <CCol col="6">
          <div style="height: 500px">
            <SimpleLeafletMap
              :center="center"
              :marker="center"
              :radius="Number(inputRadius)"
            >
            </SimpleLeafletMap>
          </div>
        </CCol>
        <CCol col="6">
          <CForm>
            <div>
              <CInput
                label="Name"
                type="text"
                v-model="inputName"
                :disabled="true"
              />
            </div>

            <div>
              <CTextarea
                label="Description"
                type="text"
                v-model="inputDescription"
                :disabled="true"
              />
            </div>
            <div>
              <CInput
                label="Lat"
                type="text"
                v-model="inputLat"
                :disabled="true"
              />
            </div>
            <div>
              <CInput
                label="Lng"
                type="text"
                v-model="inputLng"
                :disabled="true"
              />
            </div>
            <div>
              <CInput
                label="Radius"
                type="text"
                v-model="inputRadius"
                :disabled="true"
              >
              <template #append-content>
              m
              </template>
              </CInput>
              
            </div>
          <div>
            <div> It has a total of {{ tagObjectList.length }} tags </div>
            <span v-for="(tag) in tagObjectList" :key="tag.getUId()">
              <CBadge color="info" class="m-1 p-2 tagBadge" shape="pill"> 
                <span> {{tag.getName()}} </span>
              </CBadge>
            </span>
          </div>
          </CForm>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter>
      <div>
        <div class="float-right">
          <CButton @click="goToMainPage" color="success">
            OK
          </CButton>
        </div>
      </div>
    </CCardFooter>
  </CCard>
  <CCard v-if="geofenceNotFound"> 
    <CCardBody>
      The geofence with UID "{{uid}}" doesn't exist.
      Click 
      <CButton 
        variant="ghost"
        @click="goToMainPage"
      > 
      here 
      </CButton> 
      to go back to the Geofence List Page
    </CCardBody>
  </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { manualEditType } from "@/lib/geofencing_lib"
import SimpleLeafletMap from "@/components/dashboard/SimpleLeafletMap";



export default {
  components: {
    SimpleLeafletMap,
  },
  name: "GeofenceDetailsView",
  props: {

  },
  data() {
    return {
      inputDescription: null,
      inputLat: null,
      inputLng: null,
      inputName: null, 
      inputRadius: null,
      selectedCategories: [],
    };
  },
  methods: {
    goToMainPage () {
      this.$router.push({
        name: "Geofence",
      });
    },

    openManualEdit (uid) {
      this.$router.push({
        name: manualEditType.MODIFY_CIRCULAR, // h_ms_01
        params: { 
          uid,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      circularGeofences: "circularGeofences",
      geofenceCategories: "geofenceCategories",
    }),

    title () {
      return "Geofence Details";
    },

    geofence () {
      return this.circularGeofences.find(_geo => {
        return _geo.getUId() == this.uid;
      })
    },

    uid () {
      return this.$route.params.uid;
    },

    geofenceNotFound () {
      return !this.geofence;//h_ms_01
    },

    tagObjectList () {
      if (this.geofence) {
        return this.geofence.getCategories().map(tag_uid => {
          return this.geofenceCategories.find(tag => {
            return tag.getUId() == tag_uid;
          })
        })
      } else {
        return [];
      }
    },

    center () {
      return [this.inputLat, this.inputLng];
    },
  },
  watch: {
  geofence: {
    immediate: true,
    handler () {
      if (this.geofence) {
        const latlng = this.geofence.getLatLngCasted();
        this.inputName = this.geofence.getName();
        this.inputDescription = this.geofence.getDescription();
        this.inputLat = latlng[0];
        this.inputLng = latlng[1];
        this.inputRadius = this.geofence.getRadius();
        this.selectedCategories = this.geofence.getCategories();
      }
    }
  }
}
};
</script>
