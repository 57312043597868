var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.geofenceNotFound
        ? _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c(
                "CCardHeader",
                {
                  staticClass: "m-0 p-1 d-flex justify-content-between",
                  attrs: { color: "light" }
                },
                [
                  _c("span", [
                    _c("b", [_vm._v(" " + _vm._s(_vm.title) + " ")])
                  ]),
                  _c(
                    "div",
                    [
                      _c("CBadge", [_vm._v(" " + _vm._s(_vm.uid) + " ")]),
                      _c(
                        "CButton",
                        {
                          attrs: { color: "info" },
                          on: {
                            click: function($event) {
                              _vm.openManualEdit(_vm.geofence.getUId())
                            }
                          }
                        },
                        [_vm._v(" Edit ")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "CCardBody",
                { staticClass: "overflow-auto" },
                [
                  _c(
                    "CRow",
                    [
                      _c("CCol", { attrs: { col: "6" } }, [
                        _c(
                          "div",
                          { staticStyle: { height: "500px" } },
                          [
                            _c("SimpleLeafletMap", {
                              attrs: {
                                center: _vm.center,
                                marker: _vm.center,
                                radius: Number(_vm.inputRadius)
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "CCol",
                        { attrs: { col: "6" } },
                        [
                          _c("CForm", [
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Name",
                                    type: "text",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.inputName,
                                    callback: function($$v) {
                                      _vm.inputName = $$v
                                    },
                                    expression: "inputName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CTextarea", {
                                  attrs: {
                                    label: "Description",
                                    type: "text",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.inputDescription,
                                    callback: function($$v) {
                                      _vm.inputDescription = $$v
                                    },
                                    expression: "inputDescription"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Lat",
                                    type: "text",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.inputLat,
                                    callback: function($$v) {
                                      _vm.inputLat = $$v
                                    },
                                    expression: "inputLat"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Lng",
                                    type: "text",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.inputLng,
                                    callback: function($$v) {
                                      _vm.inputLng = $$v
                                    },
                                    expression: "inputLng"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("CInput", {
                                  attrs: {
                                    label: "Radius",
                                    type: "text",
                                    disabled: true
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-content",
                                        fn: function() {
                                          return [_vm._v(" m ")]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    444179610
                                  ),
                                  model: {
                                    value: _vm.inputRadius,
                                    callback: function($$v) {
                                      _vm.inputRadius = $$v
                                    },
                                    expression: "inputRadius"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("div", [
                                  _vm._v(
                                    " It has a total of " +
                                      _vm._s(_vm.tagObjectList.length) +
                                      " tags "
                                  )
                                ]),
                                _vm._l(_vm.tagObjectList, function(tag) {
                                  return _c(
                                    "span",
                                    { key: tag.getUId() },
                                    [
                                      _c(
                                        "CBadge",
                                        {
                                          staticClass: "m-1 p-2 tagBadge",
                                          attrs: {
                                            color: "info",
                                            shape: "pill"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " + _vm._s(tag.getName()) + " "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("CCardFooter", [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "success" },
                          on: { click: _vm.goToMainPage }
                        },
                        [_vm._v(" OK ")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.geofenceNotFound
        ? _c(
            "CCard",
            [
              _c(
                "CCardBody",
                [
                  _vm._v(
                    ' The geofence with UID "' +
                      _vm._s(_vm.uid) +
                      "\" doesn't exist. Click "
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { variant: "ghost" },
                      on: { click: _vm.goToMainPage }
                    },
                    [_vm._v(" here ")]
                  ),
                  _vm._v(" to go back to the Geofence List Page ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }